import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './BlogPost.css';
import bannerabout from "./1.jpg";
import banne from "./clean.jpg";

export default function BlogPost() {
  const { urls } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/blogPosts.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const foundPost = data.find(post => post.urls === urls);
        setPost(foundPost);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching the blog post:', error);
        setLoading(false);
      });
  }, [urls]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!post) {
    return (
      <div className="blog-post-full">
        <center>
          <h1>Post</h1>
          <p>Post Not Found!</p>
          <Link to="/" className="back-to-blog">Back to Blog</Link>
        </center>
      </div>
    );
  }

  return (
    <>
      {/* Breadcrumb Section */}
      <section className="breadcrumb-style1">
        <div className="breadcrumb-style1-bg" style={{ backgroundImage: 'url(${bannerabout})' }}>
        </div>
        <div className="container">
          <div className="inner-content">
            <div className="title">
              <h2>Blog - Post Details</h2>
            </div>
            <div className="breadcrumb-menu">
              <ul className="clearfix">
                <li><Link to="/">Home</Link></li>
                <li className="active">Blog Details</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Blog Details Section */}
      <section className="blog-details-area">
        <div className="container">
          <div className="row">
            {/* Blog Details Content */}
            <div className="col-xl-8">
              <div className="blog-details-box">
                <div className="blog-page-two__single">
                  <div className="blog-page-two__single-img">
                    <img src={post.image} alt={post.title} />
                  </div>
                  <div className="blog-page-two__single-content">
                    <ul className="meta-info clearfix">
                      <li><a href="#"><span className="icon-user"></span>ADMIN</a></li>
                      <li><a href="#"><span className="icon-date"></span>{new Date(post.date).toLocaleDateString()}</a></li>
                      <li><a href="#"><span className="icon-tag"></span>{post.categories}</a></li>
                    </ul>
                    <h2>{post.title}</h2>
                    <p>{post.content}</p>

                    {/* Example of additional image content */}
                   

                    <div className="tag-social-share-box">
                      <div className="tag-box clearfix">
                        <ul className="tag-list clearfix">
                          <li><a href="#">Disinfection</a></li>
                          <li><a href="#">Window</a></li>
                        </ul>
                      </div>
                      <div className="post-social-share">
                        <div className="post-social-share-links clearfix">
                          <div className="share-btn">
                            <a href="#">share <span className="icon-share"></span></a>
                          </div>
                          <ul className="clearfix">
                            <li>
                              <a href="#"><i className="icon-facebook-app-symbol"></i></a>
                            </li>
                            <li>
                              <a href="#"><i className="icon-twitter"></i></a>
                            </li>
                            <li>
                              <a href="#"><i className="icon-instagram"></i></a>
                            </li>
                            <li>
                              <a href="#"><i className="icon-youtube"></i></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <Link to="/" className="back-to-blog">Back to Blog</Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Sidebar Section */}
            <div className="col-xl-4 col-lg-7">
              <div className="thm-sidebar-box">

                <div className="single-sidebar-box sidebar-popular-services">
                  <div className="title-box">
                    <h2>Post Categories</h2>
                    <div className="border-box"></div>
                  </div>
                  <ul className="sidebar-popular-services-list">
                    <li className="current">
                      <a href="#">
                        <span className="icon-next"></span> Home Cleaning
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon-next"></span> Office Cleaning
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon-next"></span> Vehicle Cleaning
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon-next"></span> Kitchen Cleaning
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="single-sidebar-box sidebar-images-box">
                  <div className="img-holder">
                    <img src={bannerabout} alt="Sidebar Image" />
                  </div>
                
                </div>

                <div className="single-sidebar-box sidebar-popular-tag-box">
                  <div className="title-box">
                    <h2>Popular Tags</h2>
                    <div className="border-box"></div>
                  </div>
                  <div className="popular-tag-box">
                    <ul className="popular-tag clearfix">
                      <li><a href="#">Disinfection</a></li>
                      <li><a href="#">Window</a></li>
                      <li><a href="#">Kitchen</a></li>
                      <li><a href="#">Office</a></li>
                      <li><a href="#">Toilet</a></li>
                      <li><a href="#">Commercial</a></li>
                      <li><a href="#">Residential</a></li>
                      <li><a href="#">House</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
