import React, { useEffect } from "react";
// import "./Duct.css";

import Imgone from "./Imgs/1.jpg";
import Imgtwo from "./Imgs/2.jpg";

export default function () {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container servicepx">
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <br />
        <br />
        <br />
        <div
          className="row"
          style={{ paddingTop: "10vh", paddingBottom: "10vh" }}
        >
          <div
            className="col"
            style={{ display: "flex",   }}
          >
            <div>
              <div className="heading"  >
                <h1 data-aos="fade-down">
                  Water Tank Cleaning. <br />
                  <span></span>
                </h1>
              </div>
              <p>
                We provide a 100% satisfaction guarantee because we are so
                confident in the standard of our water tank cleaning service. We
                guarantee that you won't be entirely dissatisfied with the
                outcome. We value your confidence and peace of mind, and we
                guarantee the caliber of our work. Selecting Smart Box is an
                investment in safe and clean water as well as a service provider
                that puts your pleasure first.
              </p>
            </div>
          </div>
          <div className="col">
            <img src={Imgone} style={{ width: "100%" }} />
          </div>
        </div>

        <div
          className="row"
          style={{ paddingTop: "2vh", paddingBottom: "5vh" }}
        >
          <div className="col">
            <div className="heading"  >
              <h1 data-aos="fade-down">
                Importance of Water Tank Cleaning.
                <br />
                <span></span>
              </h1>
            </div>
            {/* <h2></h2> */}
            <p>
              <strong>Health and Safety:</strong> Clean water tanks prevent the
              growth and spread of bacteria, algae, and other contaminants that
              can compromise water quality. This is especially important for
              tanks used for drinking water in residential, commercial, and
              industrial settings.
            </p>
            <p>
              <strong>Preventing Diseases:</strong> Contaminated water can lead
              to waterborne diseases such as cholera, typhoid, and
              gastrointestinal illnesses. Regular cleaning reduces the risk of
              such diseases by eliminating potential sources of contamination.
            </p>
            <p>
              <strong>Tank Longevity:</strong> Cleaning helps maintain the
              structural integrity of the tank by preventing corrosion and
              buildup of sediments that can lead to leaks or other damage over
              time.
            </p>
          </div>
        </div>

        <div
          className="row"
          style={{ paddingTop: "2vh", paddingBottom: "5vh" }}
        >
          <div className="col">
            {/* <h2>Process of Water Tank Cleaning</h2> */}
            <div className="heading"  >
              <h1 data-aos="fade-down">
                Process of Water Tank Cleaning.
                <br />
                <span></span>
              </h1>
            </div>
            <p>
              <strong>Preparation:</strong> Before cleaning, the tank is emptied
              of water to facilitate thorough cleaning. This may involve using
              pumps or gravity drains depending on the tank’s design.
            </p>
            <p>
              <strong>Scrubbing and Disinfection:</strong> The interior surfaces
              of the tank are scrubbed to remove sediment, biofilms, and other
              residues. High-pressure hoses or scrub brushes are typically used.
              After scrubbing, the tank is disinfected using appropriate
              chemicals to kill bacteria and pathogens. Chlorine-based
              disinfectants are commonly used but should be applied according to
              recommended concentrations and contact times to ensure
              effectiveness and safety.
            </p>
            <p>
              <strong>Rinsing:</strong> After disinfection, the tank is
              thoroughly rinsed with clean water to remove disinfectant residues
              and loosened debris. This step is crucial to prevent any lingering
              chemicals from contaminating the water.
            </p>
            <p>
              <strong>Inspection:</strong> Post-cleaning, the tank is inspected
              for any signs of damage, remaining contaminants, or incomplete
              cleaning. This ensures that the tank is ready to be refilled with
              clean water.
            </p>
            <p>
              <strong>Refilling:</strong> Once cleaned and inspected, the tank
              is refilled with water from a trusted source. It’s important to
              monitor the quality of the water after refilling to ensure it
              meets safety standards.
            </p>
          </div>
        </div>

        <div
          className="row"
          style={{ paddingTop: "2vh", paddingBottom: "5vh" }}
        >
          <div className="col">
            <div className="heading"  >
              <h1 data-aos="fade-down">
                Frequency of Cleaning.
                <br />
                <span></span>
              </h1>
            </div>
            {/* <h2></h2> */}
            <p>
              <strong>Residential Tanks:</strong> Typically cleaned annually or
              bi-annually, depending on water usage and local regulations.
            </p>
            <p>
              <strong>Commercial and Industrial Tanks:</strong> Cleaning
              frequency may vary based on usage, water quality standards, and
              specific industry regulations.
            </p>
          </div>
        </div>

        <div
          className="row"
          style={{ paddingTop: "2vh", paddingBottom: "5vh" }}
        >
          <div className="col">
            <div className="heading"  >
              <h1 data-aos="fade-down">
                Professional Help.
                <br />
                <span></span>
              </h1>
            </div>
            {/* <h2>Professional Help</h2> */}
            <p>
              For larger or more complex tanks, or if unsure about the cleaning
              process, hiring professional tank cleaning services is
              recommended. Professionals have the expertise, equipment, and
              knowledge of safety protocols necessary to perform thorough and
              effective cleaning while adhering to regulatory standards.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
