import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import Whatsapp from "../../img/whatsapp.png";
import footerLogo from '../../img/Icon.png';

const Footer = () => {
  return (
    <>
      <section className="slogan-style3">
        <div className="container">
          <div className="slogan-style3__inner">
            <div className="slogan-style3__content">
              <h2>Schedule your appointment effortlessly</h2>
              <p>Choose your preferred date and time, and receive instant confirmation</p>
            </div>
            <div className="slogan-style3__btn">
              <Link to="/Quote" className="btn-one">
                <span className="txt">GET QUOTE</span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer-style1">
        <div className="footer-main">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 single-widget">
                <div className="single-footer-widget marbtm">
                  <div className="our-company-info">
                    <div className="footer-logo-style1">
                      <Link to="/">
                        <img src={footerLogo} alt="Footer Logo" />
                      </Link>
                    </div>
                    <div className="text">
                      <p>Providing professional cleaning and maintenance services tailored to meet your needs.</p>
                    </div>
                    <div className="phone-number">
                      <p><a href="/Quote">  Get a Free Quotation </a></p> 
                   
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 single-widget">
                <div className="single-footer-widget margin-left single-footer-widget--link-box">
                  <div className="title">
                    <h3>Pages</h3>
                  </div>
                  <div className="footer-widget-links">
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li><Link to="/About">About Us</Link></li>
                      <li><Link to="/Services">Services</Link></li>
                      <li><Link to="/ContactUs">Contact Us</Link></li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 single-widget">
                <div className="single-footer-widget margin-left single-footer-widget--link-box">
                  <div className="title">
                    <h3>Our Services</h3>
                  </div>
                  <div className="footer-widget-links">
                    <ul>
                      <li><Link to="/AcDuctCleaningandDisinfection">AcDuct Cleaning</Link></li>
                      <li><Link to="/Greasetrapcleaning">Grease trap Cleaning</Link></li>
                      <li><Link to="/WaterTankCleaning">Water Tank Cleaning</Link></li>
                      <li><Link to="/KitchenHoodDuctExhaustFanCleaning">KitchenHood & Fan Cleaning</Link></li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 single-widget">
                <div className="single-footer-widget margin-left single-footer-widget--link-box">
                  <div className="title">
                    <h3>Contact Us</h3>
                  </div>
                  <div className="footer-widget-contact-info">
                    <ul>
                      <li><a href="tel:+97122463894">+971 22 463 894</a></li>
                      <li><a href="tel:+971502424996">+971569147880</a></li>
                      <li>Hours: Mon-Sat 8am to 7pm</li>
                    </ul>
                  </div>
                  <div className="footer-social-link">
                    <ul>
                    <li><a href="https://www.linkedin.com/company/smartbox-brokerservices/"><i className="fab fa-linkedin"></i></a></li>
                    <li><a href="https://www.facebook.com/SMART-BOX-Broker-Services-100484859689193"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://instagram.com/smartbox.brokerservices?igshid=YmMyMTA2M2Y="><i className="fab fa-instagram"></i></a></li>
                   

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="bottom-inner">
              <div className="copyright-text">
                <p>© Copyright 2024 Smartbox General Cleaning. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <a className="whatsapp-link" href="https://api.whatsapp.com/send?phone=971502424996" target="_blank" rel="noopener noreferrer">
        <img className="whatsapp" src={Whatsapp} alt="WhatsApp" />
      </a>
    </>
  );
};

export default Footer;