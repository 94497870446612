import React, { useState, useEffect } from "react";
import { Input, Modal, message } from "antd";
import axios from "axios";
import { USER_API_URL } from "../../../Api/Api";
import StripeForm from "./Card/Card";

export default function Ac() {
  axios.defaults.withCredentials = true;

  const [selectedAcType, setSelectedAcType] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedServicePr, setSelectedServicePr] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [vat, setVat] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [visible, setVisible] = useState(false);
  const [sch, setSch] = useState(""); // State to hold booking reference
  const [bookingData, setBookingData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const unitValue = [
    { name: "Unit 1", price: 300 },
    { name: "Unit 2", price: 500 },
    { name: "Unit 3", price: 800 },
    { name: "Unit 4", price: 100 },
  ];

  const typtValue = [
    { name: "Regular Cleaning", price: 130 },
    { name: "Cleaning And Repair", price: 150 },
  ];

  useEffect(() => {
    const amount = selectedUnit ? selectedUnit.price : 0;
    const vatAmount = (amount * 5) / 100;
    const netAmount = amount + vatAmount;
    setTotalAmount(amount);
    setVat(vatAmount);
    setNetAmount(netAmount);
  }, [selectedUnit]);

  useEffect(() => {
    const amount = selectedService
      ? selectedService.price + selectedServicePr
      : 0;
    const vatAmount = (amount * 5) / 100;
    const netAmount = amount + vatAmount;
    setTotalAmount(amount);
    setVat(vatAmount);
    setNetAmount(netAmount);
  }, [selectedServicePr]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleContactChange = (e) => {
    setContact(e.target.value);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    onBooking();
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleManageBooking = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${USER_API_URL}/ac/${sch}`, {
        withCredentials: true,
      });
      setBookingData(response.data);
      message.success("Booking details fetched successfully");
    } catch (error) {
      message.error("Error fetching booking details");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const onBooking = async () => {
    const data = {
      SelectedUnit: selectedUnit,
      SelectedService: selectedService,
      SelectedDate: selectedServicePr,
      Total_Amount: totalAmount,
      VAT: vat,
      Net_Amount: netAmount,
      Email: email,
      Contact: contact,
      Name: name,
    };
    try {
      const response = await axios.post(`${USER_API_URL}/ac`, data, {
        withCredentials: true,
      });
      console.log("Response data:", response.data);
      message.success("Booking Successful");
    } catch (error) {
      message.error("Booking Failed");
      console.error("Error:", error);
    }
  };

  return (
    <div className="container">
      
      <Modal
        title="Booking"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Request Booking"}
        width={800}
      >
        <div className="row">
          <div className="col">
            <StripeForm/>
          </div>
          <div className="col">
            <p>Enter your name:</p>
            <Input
              value={name}
              onChange={handleNameChange}
              style={{ height: 40 }}
              placeholder="Your Name"
            />
            <p>Enter your email:</p>
            <Input
              value={email}
              onChange={handleEmailChange}
              style={{ height: 40 }}
              placeholder="Email Address"
            />
            <p>Enter your phone:</p>
            <Input
              value={contact}
              onChange={handleContactChange}
              style={{ height: 40 }}
              placeholder="Contact Number"
            />
          </div>
        </div>
      </Modal>

      <div className="container">
        <br />
        <br />
        <div align="center">
          <div className="heading" align="center">
            <h1 data-aos="fade-down">
              Ac Cleaning
              <br />
            </h1>
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-lg-7 col-md-6 col-sm-12 colxs-12 ">
            <div className="whit">
              <div className="often">
                <h3>Choose Ac type</h3>
                <div>
                  <label
                    style={{
                      background:
                        selectedAcType === "Split Ac" ? "#df2528" : "none",
                      color: selectedAcType === "Split Ac" ? "white" : "black",
                      width: "40%",
                    }}
                    onClick={() => {
                      setSelectedAcType("Split Ac");
                      setSelectedUnit(null); // Reset selected unit
                      setSelectedService(null); // Reset selected service
                      setSelectedServicePr(0); // Reset selected service price
                    }}
                  >
                    Split Ac
                  </label>
                  <label
                    style={{
                      background:
                        selectedAcType === "Centralized and Duct Ac"
                          ? "#df2528"
                          : "none",
                      color:
                        selectedAcType === "Centralized and Duct Ac"
                          ? "white"
                          : "black",
                      width: "40%",
                    }}
                    onClick={() => {
                      setSelectedAcType("Centralized and Duct Ac");
                      setSelectedUnit(null); // Reset selected unit
                      setSelectedService(null); // Reset selected service
                      setSelectedServicePr(0); // Reset selected service price
                    }}
                  >
                    Centralized and Duct Ac
                  </label>
                </div>
              </div>
              {selectedAcType === "Centralized and Duct Ac" && (
                <div>
                  <div className="often">
                    <h3>Select Unit</h3>
                    <div>
                      {unitValue.map((unit, index) => (
                        <label
                          key={index}
                          style={{
                            width: "25%",
                            background:
                              selectedUnit && selectedUnit.name === unit.name
                                ? "#df2528"
                                : "none",
                            color:
                              selectedUnit && selectedUnit.name === unit.name
                                ? "white"
                                : "black",
                          }}
                          onClick={() => {
                            setSelectedUnit(unit);
                            const amount =
                              unit.price +
                              (selectedService ? selectedService.price : 0);
                            const vatAmount = (amount * 5) / 100; // Calculating VAT (5%)
                            const netAmount = amount + vatAmount; // Calculating net amount
                            setTotalAmount(amount);
                            setVat(vatAmount);
                            setNetAmount(netAmount);
                          }}
                        >
                          {unit.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <br />
                </div>
              )}

              {selectedAcType === "Split Ac" && (
                <div>
                  <div className="often">
                    <h3>Select Service</h3>
                    <div>
                      {typtValue.map((service, index) => (
                        <label
                          key={index}
                          style={{
                            width: "25%",
                            background:
                              selectedService &&
                              selectedService.name === service.name
                                ? "#df2528"
                                : "none",
                            color:
                              selectedService &&
                              selectedService.name === service.name
                                ? "white"
                                : "black",
                          }}
                          onClick={() => {
                            setSelectedService(service);
                            setSelectedServicePr(service.price);
                          }}
                        >
                          {service.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <br />
                </div>
              )}

              <div className="datesi">
                <h3>Schedule</h3>
                <div>
                  <input
                    type="datetime-local"
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col" style={{ padding: 10 }}>
            <div
              className="whit"
              style={{ background: "white", margin: "5px" }}
            >
              <br />
              <h2>Booking Summary</h2>
              <hr />
              <br />
              <div className="row">
                <div className="col">Selected Unit :</div>{" "}
                <div className="col">
                  {selectedUnit ? selectedUnit.price.toFixed(2) : "N/A"}
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">Selected Service :</div>{" "}
                <div className="col">
                  {selectedService ? selectedServicePr.toFixed(2) : "N/A"}
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">Selected Date :</div>{" "}
                <div className="col">{selectedDate ? selectedDate : "N/A"}</div>
              </div>
              <br />
              <div className="row">
                <div className="col">Total Amount:</div>
                <div className="col">{totalAmount.toFixed(2)} AED</div>
              </div>
              <br />
              <div className="row">
                <div className="col">VAT (5%):</div>
                <div className="col">{vat.toFixed(2)} AED</div>
              </div>
              <br />
              <div className="row">
                <div className="col">Net to pay:</div>
                <div className="col">{netAmount.toFixed(2)} AED</div>
              </div>
            </div>
            <button className="btn btn-success bknow" onClick={showModal}>
              BOOK NOW
            </button>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
