import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faHome, faClock, faGlobe, faShareAlt, faCamera, faVideo } from '@fortawesome/free-solid-svg-icons';
import './Contact.css';
import bannerabout from "./bg.jpg";

const ContactUs = () => {
  return (
    <div className="contact-page">
      {/* Breadcrumb */}
      <section className="breadcrumb-style1">
        <div className="breadcrumb-style1-bg" style={{
          backgroundImage: `url(${bannerabout})`,
          height: '200px'
        }}></div>
        <div className="container">
          <div className="inner-content">
            <div className="title">
              <h2>Contact</h2>
            </div>
            <div className="breadcrumb-menu">
              <ul>
                <li><a href="/">Home</a></li>
                <li className="active">Contact</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Info */}
      <section className="contact-info-style1">
        <div className="container">
          <div className="contact-info-style1__inner">
            <ul>
              <li>
                <div className="icon-box">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <div className="content-box">
                  <p>Phone Number</p>
                  <h4><a href="tel:+123456789">+971 22 463 894</a></h4>
                </div>
              </li>
              <li>
                <div className="icon-box">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div className="content-box">
                  <p>Email Address</p>
                  <h4><a href="mailto:info@example.com">info@smartboxcleaningservices.com</a></h4>
                </div>
              </li>
              <li>
                <div className="icon-box">
                  <FontAwesomeIcon icon={faHome} />
                </div>
                <div className="content-box">
                  <p>Office Address</p>
                  <h4>United Arab Emirates</h4>
                </div>
              </li>
              <li>
                <div className="icon-box">
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <div className="content-box">
                  <p>Working Hours</p>
                  <h4>Mon-Fri 8am to 7pm</h4>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* Contact Form */}
      <section className="main-contact-form-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-12">
              <div className="contact-page__contact-info">
                <div className="sec-title">
                  <h4>Contact With Us</h4>
                  <h2>Have queries? Feel free to write us</h2>
                </div>
                <div className="text">
                  <p>Smart Box General Maintenance & Cleaning Services LLC is a growing commercial cleaning and general maintenance provider located in Abu Dhabi, United Arab Emirates.</p>
                </div>
                <div className="social-links">
                  <ul>
                    <li><a href="#"><FontAwesomeIcon icon={faGlobe} /></a></li>
                    <li><a href="#"><FontAwesomeIcon icon={faShareAlt} /></a></li>
                    <li><a href="#"><FontAwesomeIcon icon={faCamera} /></a></li>
                    <li><a href="#"><FontAwesomeIcon icon={faVideo} /></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-12">
              <div className="contact-form">
                <form className="default-form2">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="form-group">
                        <input type="text" name="name" placeholder="Your Name" required />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group">
                        <input type="email" name="email" placeholder="Email" required />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="form-group">
                        <input type="text" name="phone" placeholder="Phone" />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group">
                        <input type="text" name="subject" placeholder="Subject" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group">
                        <textarea name="message" placeholder="Message" required></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="button-box">
                        <button className="btn-one" type="submit">
                          <span className="txt">
                            Submit Now
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Google Map */}
      <section className="google-map1">
        <div className="container-fluid">
          <div className="contact-page-map-outer-box">
            <div className="google-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14523.149101998735!2d54.3742112!3d24.4928264!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e67072212f301%3A0x9933a766f3fd1a82!2sSMART%20BOX%20BROKERS%20SERVICES%20LLC!5e0!3m2!1sen!2sae!4v1682887713401!5m2!1sen!2sae"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;