import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBroom, faSnowflake, faUtensils, faWater, faVial, faWind, faOilCan, faHardHat, faTools } from '@fortawesome/free-solid-svg-icons';

import './About.css';
import general from "./general.png";
import bannerabout from "./bg.jpg";

const AboutUs = () => {
  const outclients = [
    "Pharmaceuticals",
    "School and colleges",
    "Real estate and developers",
    "Hotel and restaurants",
    "Corporate sectors",
    "Retail and Malls",
    "Industries and Factories",
  ];
  const whyChooseUs = [
    {
      title: "Customer Focused",
      description: "We are customer focused by giving our clients excellent service levels that meet their expectations which is utmost important to us."
    },
    {
      title: "Training",
      description: "As part of our employee training program, we provide entry level training for staff as well as on the job skill enhancements."
    },
    {
      title: "Professionalism",
      description: "Throughout our business relationships, we strive to maintain high ethical standards, fairness, and honesty."
    },
    {
      title: "Reliability",
      description: "In order to ensure continuous improvement in both our customer service and product offering, we adhere to ISO 9000's quality standards."
    },
    {
      title: "Professionalism",
      description: "Throughout our business relationships, we strive to maintain high ethical standards, fairness, and honesty."
    },
    {
      title: "100% Satisfaction Guaranteed!",
      description: "Please let us know if there are any errors in our work. We will do whatever it takes to fix them."
    }
  ];
  const services = [
    { name: "Home cleaning and Office cleaning", icon: faHome },
    { name: "Nanny and Maid services", icon: faBroom },
    { name: "AC Duct Cleaning & Disinfection & AC Maintenance", icon: faSnowflake },
    { name: "Kitchen Extract Duct Cleaning and De-Greasing", icon: faUtensils },
    { name: "Water Tank Cleaning & Disinfection Service", icon: faWater },
    { name: "Water & Air Testing", icon: faVial },
    { name: "Air Treatment", icon: faWind },
    { name: "Grease Trap Cleaning & Installation", icon: faOilCan },
    { name: "Civil & MEP Maintenance", icon: faHardHat },
    { name: "Post construction cleaning", icon: faTools }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 300, // Increased speed
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Faster auto-sliding
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="about-page">
      <section className="breadcrumb-style1">
      <div 
  className="breadcrumb-style1-bg" 
  style={{
    backgroundImage: `url(${bannerabout})`,
    height: '150px'
  }}
></div>
        <div className="container">
          <div className="inner-content">
            <div className="title">
              <h2>About Us</h2>
            </div>
            <div className="breadcrumb-menu">
              <ul>
                <li><Link to="/">Home</Link></li>
                <li className="active">About</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="about-style1 about-style1--about-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-5">
              <div className="about-style1__img-box">
                <div className="inner">
                  <img src={general} alt="About SMARTBOX" />
                </div>
                <div className="experience-box">
                  <div className="experience-box__inner">
                    <div className="experience-box__inner-content">
                      <div className="counting">
                        <h2 className="odometer" data-count="20">5</h2>
                        <span className="icon-add plus"></span>
                      </div>
                      <h4>Years of<br /> Experience</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-7">
              <div className="about-style1__content-box">
                <div className="sec-title">
                  <h1>ABOUT <span>SMARTBOX</span></h1>
                </div>
                <div className="about-style1__content-box-inner">
                  <p>Smart Box General Maintenance & Cleaning Services LLC is a growing commercial cleaning and general maintenance provider located in Abu Dhabi, United Arab Emirates. We provide a range of cleaning services for homes; commercial and industrial clients and we also work along with real Estate agents in order to arrange end of lease clean ups for residential properties.</p>
                  <p>Smart Box General Maintenance & Cleaning Services LLC brings a fresh and professional approach to general maintenance and cleaning services; our goal is to exceed the expectations of every client by offering outstanding customer service, exceptional quality, and add greater value for the service.</p>
                  <div className="btn-secondary">
                    <Link to="/Profile.pdf" className="btn-one">Download Our Company Profile </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="vision-mission">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>VISION</h2>
              <p>Our vision is to fully understand our clients' needs and exceed their expectations throughout the project life cycle of general maintenance and cleaning services</p>
            </div>
            <div className="col-md-6">
              <h2>MISSION STATEMENT</h2>
              <p>To provide comprehensive, high-quality services to our clients with the highest level of integrity and professionalism and to create clean, peaceful work environments for them.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us">
        <div className="container">
          <h2>WHY CHOOSE SMART BOX</h2>
          <div className="row">
            {whyChooseUs.map((item, index) => (
              <div key={index} className="col-md-4 col-sm-6">
                <div className="reason-card">
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="our-services">
        <div className="container">
          <h2>OUR SERVICES</h2>
          <Slider {...settings}>
            {services.map((service, index) => (
              <div key={index} className="service-slide">
                <div className="service-card">
                  <div className="service-icon">
                    <FontAwesomeIcon icon={service.icon} />
                  </div>
                  <p>{service.name}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>


      <section className="our-clients">
        <div className="container">
          <h2>OUR CLIENTS</h2>
          <div className="client-list">
            {outclients.map((client, index) => (
              <span key={index} className="client-label">{client}</span>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;