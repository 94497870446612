import React, { useEffect, useState } from "react";
import { Input, message, Modal } from "antd";
import axios from "axios";
import { USER_API_URL } from "../../../Api/Api";
import StripeForm from "./Card/Card";

export default function WashAndFold() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  axios.defaults.withCredentials = true;
  const [clothSize, setclothSize] = useState([
    { size: "Small Pouch - (UP 10 ITEMS)", selected: false, price: 35 },
    { size: "Meduim Pouch - (UP 20 ITEMS)", selected: false, price: 45 },
    { size: "Large Pouch - (UP 30 ITEMS)", selected: false, price: 45 },
  ]);

  const [startDate, setStartDate] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [visible, setVisible] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleContactChange = (e) => {
    setContact(e.target.value);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    onBooking();
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSizeClick = (index) => {
    const updatedSizes = clothSize.map((carpet, i) => ({
      ...carpet,
      selected: i === index ? !carpet.selected : carpet.selected,
    }));
    setclothSize(updatedSizes);
  };

  const calculateTotalAmount = () => {
    return clothSize.reduce((total, item) => {
      return item.selected ? total + item.price : total;
    }, 0);
  };

  const calculateVAT = () => {
    const totalAmount = calculateTotalAmount();
    return (totalAmount * 0.05).toFixed(2);
  };

  const calculateNetCost = () => {
    const totalAmount = calculateTotalAmount();
    const vat = calculateVAT();
    return (totalAmount + parseFloat(vat)).toFixed(2);
  };

  const onBooking = async() => {
    const bookingDetails = {
      Name :name,
      Email : email,
      Contact : contact,
      startDate,
      booking: clothSize.filter(item => item.selected),
      totalPrice: calculateTotalAmount(),
      vatAmount: calculateVAT(),
      totalAmount: calculateNetCost(),
    };
    console.log("Booking", bookingDetails )
    try {
      const response = await axios.post(`${USER_API_URL}/washandfold`, bookingDetails);
      // console.log("Booking response:", values);
      message.success("Booking successful!");
      // Optionally reset form or update state
    } catch (error) {
      console.error("Booking failed:", error);
      message.error("Booking Failed!");
      // alert("Booking failed. Please try again.");
    }
  };

  return (
    <div className="container">
      <br />
      <Modal
        title="Booking"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Request Booking"}
        width={800}
      >
        <div className="row">
          <div className="col">
            <StripeForm/>
          </div>
          <div className="col">
            <div>
              <p>Enter your name:</p>
              <Input
                value={name}
                onChange={handleNameChange}
                style={{ height: 40 }}
                placeholder="Your Name"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your email:</p>
              <Input
                value={email}
                onChange={handleEmailChange}
                style={{ height: 40 }}
                placeholder="Email Address"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your phone:</p>
              <Input
                value={contact}
                onChange={handleContactChange}
                style={{ height: 40 }}
                placeholder="Contact Number"
              />
            </div>
          </div>
        </div>
      </Modal>
      <br />
      <div align="center">
        <div className="heading" align="center">
          <h1 data-aos="fade-down">
            Wash And Fold
            <br />
          </h1>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-lg-7 col-md-6 col-sm-12 colxs-12">
          <div className="whit">
            <div>
              <div className="often">
                <h3>Select Size?</h3>
                <div>
                  {clothSize.map((carpet, index) => (
                    <label
                      key={index}
                      onClick={() => handleSizeClick(index)}
                      style={
                        carpet.selected
                          ? { background: "lightgrey", width: "45%" }
                          : { background: "none", width: "45%" }
                      }
                    >
                      {carpet.size}
                    </label>
                  ))}
                </div>
              </div>
              <br />
              <div className="datesi">
                <h3>Pick your start date and time?</h3>
                <div>
                  <input
                    type="datetime-local"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col" style={{ padding: 10 }}>
          <div className="whit" style={{ background: "white", margin: "5px" }}>
            <br />
            <h2>Booking Summary</h2>
            <hr />
            {clothSize.map((carpet, index) => {
              if (carpet.selected) {
                return (
                  <div className="row" key={index}>
                    <div className="col">{carpet.size}</div>
                    <div className="col">{carpet.price} AED</div>
                  </div>
                );
              }
              return null;
            })}
            <br />
            <div className="row">
              <div className="col">Total Amount:</div>
              <div className="col">{calculateTotalAmount()} AED</div>
            </div>
            <div className="row">
              <div className="col">VAT (5%):</div>
              <div className="col">{calculateVAT()} AED</div>
            </div>
            <div className="row">
              <div className="col">Net to pay:</div>
              <div className="col">{calculateNetCost()} AED</div>
            </div>
          </div>
          <button className="btn btn-success bknow" onClick={showModal}>
            BOOK NOW
          </button>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
