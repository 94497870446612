import React, { useEffect } from "react";
import "./Duct.css";
import Imgone from "./Imgs/1.jpg";
import Imgone2 from "./Imgs/2.jpg";

export default function () {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container servicepx">
      <div
        style={{
          //   height: "40vh",
          //   display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <br />
        <br />
        <br />
        <br />

        <div className="heading" align="center">
          <h1 data-aos="fade-down">
            Kitchen Hood, <br />
            <span> Duct & Exhaust Fan Cleaning </span>
          </h1>
        </div>
        <h4 align="center"></h4>
        <br />
        <br />
        <p align="center">
          Beyond a hood cleaner, Smart Box is more. Being a reliable business
          partner for commercial kitchen exhaust system needs is the foundation
          of our entire company. In addition to professional commercial kitchen
          exhaust hood cleaning, we also provide fan maintenance and repair,
          filter exchange, and other services. We take great satisfaction in
          maintaining consistent, punctual, and thorough interactions with you;
          in addition, we invest in modern equipment to meet the specific
          requirements of your system; and we hire, train, and manage our staff
          with attention.
        </p>
        <hr />
        <div
          className="row"
          style={{ paddingTop: "10vh", paddingBottom: "10vh" }}
        >
          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <img src={Imgone2} style={{ width: "100%" }} />
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12 col-xs-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <div className="heading" align="center">
                <h1 data-aos="fade-down">
                  Kitchen Exhaust Cleaning <br />
                  <br />
                  <span> </span>
                </h1>
              </div>
              <h3></h3>
              <p>
                Your kitchen exhaust system puts a lot of effort into removing{" "}
                <br /> any airborne pollutants from cooking and other kitchen
                operations. <br /> To maintain healthy indoor air quality, the
                system is essential. You <br /> might not be aware,
                nevertheless, that the system requires to be cleaned out
                regularly.
                <br /> If it isn't, then may be risks to the safety of your
                entire house or place of business, <br /> not just your kitchen.
              </p>
            </div>
          </div>
        </div>

        <div align="center">
          <h2 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
            Effect of not cleaning the duct{" "}
          </h2>
        </div>
        <div
          className="row"
          style={{ paddingTop: "5vh", paddingBottom: "10vh" }}
        >
          <div
            className="col-lg-6 col-md-12 col-sm-12 col-xs-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <div className="heading" align="center">
                <h1 data-aos="fade-down" style={{ fontSzie: "11px" }}>
                  Fire Hazard
                  <br />
                  <br />
                  <span> </span>
                </h1>
              </div>

              <p>
                By keeping your kitchen exhaust system clean, you can avoid a
                very serious
                <br /> fire threat. Grease and other materials are collected by
                exhaust systems, and <br /> when they accumulate, the extractor
                may malfunction. This reduces the rate of <br /> airflow in the
                kitchen, creating the ideal conditions for a fire to start.
                Cleaning out <br /> the system is the first step towards
                avoiding kitchen exhaust fires.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col">
            {/* <img src={Imgone2} style={{ width: "100%" }} /> */}
            <img src={Imgone} style={{ width: "100%" }} />
          </div>
        </div>
        <div
          className="row"
          style={{ paddingTop: "10vh", paddingBottom: "10vh" }}
        >
          <div className="">
            <div className="heading" align="center">
              <h1 data-aos="fade-down" style={{ fontSzie: "11px" }}>
                System Failure
                <br />
                <br />
                <span> </span>
              </h1>
            </div>
            <div align="center">
              <p>
                An unclean kitchen exhaust system can also cause it to fail
                early. Any system, including a kitchen exhaust, needs to have
                all of its components maintained in order for it to function. It
                needs maintenance to avoid providing a fire risk, releasing
                smells, failing, operating below optimal efficiency, or cycling
                unhealthy air. Its proper functioning will be guaranteed by
                having it professionally cleaned, eliminating any possibility of
                dangers or costly premature replacements.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
