import React from "react";
import Grease from "./Greasetrapcleaning";

const GreaseTrapService = () => {
  return (
    <div className="container my-5">
      <Grease />
      <div className="heading" align="">
        <h1 data-aos="fade-down">
          How Often Should I Schedule Grease Trap Service? <br />
          <span></span>
        </h1>
      </div>
      <section className="mb-5">
        <p>
          The frequency of servicing your trap is contingent upon certain
          elements:
        </p>
        <ul>
          <li>The dimensions of the trap</li>
          <li>The contents of the trap</li>
          <li>Local laws, rules, and their application</li>
        </ul>
        <p>
          Municipalities that have pumping ordinances often need a cycle that
          occurs at least once every 30 days. Although some of our clients
          choose not to abide by those rules, they frequently discover that
          Johnny Law eventually finds out.
        </p>
      </section>
      <section className="mb-5">
        <h2>Types of Grease Traps</h2>
        <p>
          There are two main types of traps: tiny traps and huge traps. Small
          traps, 50 gallons or less, are typically found in the kitchen. Large
          traps, usually a thousand gallons or larger, are situated outside the
          eatery. 50-gallon traps typically require cleaning, depending on the
          amount of waste placed inside.
        </p>
        <p>
          The dishwasher is the main source of grease in restaurants, something
          that most owners are unaware of. Scraping food and grease off of
          dishes, cookware, and utensils before running them through the
          dishwasher is a best practice. This may, in certain cases,
          considerably lessen the quantity of grease that the grease trap
          collects. By altering these culinary procedures, restaurants with
          smaller traps may find that they can go longer between cleanings.
        </p>
      </section>

      <section className="mb-5">
        <h2>Cleaning Frequency</h2>
        <p>
          If your trap is tiny, you may be able to gauge from past experience
          how often it has to be cleaned. When there is too much oil in it, the
          sinks usually begin to smell bad or drain more slowly, especially if
          you empty many compartments at once. To reduce kitchen crises,
          consider modifying your service cycle to take place earlier rather
          than later if you are experiencing issues.
        </p>
        <p>
          Bigger traps are made to last for at least ninety-nine days in between
          refills. Having said that, if your trap collects a lot of oil or food
          particles, you may need to check it more regularly than every 90 days.
          These changes depend on kitchen procedures and the type of prepared
          food. A sandwich shop that doesn't use plates to serve its food, for
          instance, will produce a lot less grease than the majority of Mexican
          restaurants.
        </p>
      </section>

      <section className="mb-5">
        <div className="heading" align="center">
          <h1 data-aos="fade-down">
            Get Started <br />
            <span></span>
          </h1>
        </div>
        <p>
          Are you prepared to begin? Give us a call, and one of our account
          managers will arrange for service and help you choose the cycle that
          works best for your business.
        </p>
      </section>
    </div>
  );
};

export default GreaseTrapService;
