// src/StripeForm.js
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import "./Card.css"

const stripePromise = loadStripe('your-publishable-key-here');

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [address, setAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  });

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card, { address });

    if (result.error) {
      console.log(result.error.message);
    } else {
      console.log(result.token);
    }
  };

  return (
    <form className="xcard" onSubmit={handleSubmit} style={{paddingTop:15}}>
        
      <label>
        Address Line 1
        <input
          type="text"
          name="line1"
          value={address.line1}
          onChange={handleAddressChange}
        />
      </label>
      <label>
        Address Line 2
        <input
          type="text"
          name="line2"
          value={address.line2}
          onChange={handleAddressChange}
        />
      </label>
      <label>
        City
        <input
          type="text"
          name="city"
          value={address.city}
          onChange={handleAddressChange}
        />
      </label>
      
      <label>
        Country
        <input
          type="text"
          name="country"
          value={address.country}
          onChange={handleAddressChange}
        />
      </label>
      <br/>
      <br/>
      <CardElement />
      {/* <button type="submit" disabled={!stripe}>
        Pay
      </button> */}
    </form>
  );
};

const StripeForm = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default StripeForm;
