import React, { useEffect } from "react";
import Imgone from "./grease.jpg";
import Imgtwo from "./other.jpg";

const DrainLine = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container my-5" align="center">
      <div className="heading" align="center">
        <h1 data-aos="fade-down">
          Drain Line
          <span> Cleaning</span>
        </h1>
      </div>

      <section className="mb-5">
        <div className="heading" align="center">
          <h1 data-aos="fade-down">
            {/* When to Call a Professional <br /> */}
            <span></span>
          </h1>
        </div>
        <p>
          It's time to call a professional drain cleaning business if your
          drains are overflowing, your sink, bath, or shower won't drain
          <br />
          correctly, or if it smells awful. Blocked drains can cause a variety
          <br />
          of difficulties. With expert equipment and speed, our drain unblocking
          specialists remove all kinds of blockages from drains.
        </p>
      </section>

      <div className="row">
        <div className="col">
          <img src={Imgone} style={{ width: "100%" }} />
        </div>
        <div className="col">
          <section className="mb-5" align="left">
            <div className="heading" align=" ">
              <h1 data-aos="fade-down">
                Common Causes of Drainage Problems <br />
                <span></span>
              </h1>
            </div>

            <ul style={{ listStyle: "none" }}>
              <li>Home waste obstructing drains</li>
              <li>Tree roots entering pipes</li>
              <li>Damaged and broken pipes</li>
              <li>Broken Drain Rods</li>
              <li>Obstructed Gully</li>
            </ul>
          </section>
        </div>{" "}
      </div>

      <br/>
      <br/>
      <br/>

      <section className="mb-5">
        <div className="heading" align="center">
          <h1 data-aos="fade-down">
            How Do We Unblock Your Drains? <br />
            <span></span>
          </h1>
        </div>
        <p>
          High-pressure water can be used to break up the obstruction causing
          the choke and pull debris into the main public sewer. Depending on the
          extent of the choke, a huge industrial vacuum pump can be used to
          remove the material from the pipe by sucking it through a manhole in
          the road.
        </p>
        <p>
          Pressure is applied to the water through a specially made nozzle,
          which shoots water jets at the choke and the pipe's surface to
          dislodge the debris and let it flow downstream with the water to the
          main sewer or suction hose.
        </p>
      </section>

      <div className="row">
        <div className="col">
          {" "}
          <img src={Imgtwo} style={{ width: "100%" }} />
        </div>
        <div className="col">
          <section className="mb-5" align="left">
            <div className="heading" align="">
              <h1 data-aos="fade-down">
                Best Practices to Look After Your Drains? <br />
                <span></span>
              </h1>
            </div>
            <ul>
              <li>Put food waste in the trash can.</li>
              <li>
                Grease and oil should not be poured down the drain as they may
                solidify and cause a stubborn blockage that may be challenging
                to clear on your own.
              </li>
              <li>
                Reduce the amount of hair and soap scum that drains away by
                covering the drain openings with a drain screen.
              </li>
              <li>
                Regularly use a drain cleaning product to get rid of any
                obstructions before they cause issues.
              </li>
            </ul>
          </section>
        </div>{" "}
      </div>
      
      <br/>
      <br/>
      <br/>

      <section className="mb-5">
        <div className="heading" align="center">
          <h1 data-aos="fade-down">
            How Often Should I Have My Drains Looked At? <br />
            <span></span>
          </h1>
        </div>
        <p>
          Although there are various methods for unclogging your drains at home,
          getting them professionally cleaned periodically is advised as a
          preventative step, especially if you manage or own a business and your
          drains are used more frequently than usual.
        </p>
        <p>
          It could be a good idea to call us if your drain clogs frequently
          since our skilled drainage specialists can locate and resolve the
          problem.
        </p>
      </section>
    </div>
  );
};

export default DrainLine;
