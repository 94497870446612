import React, { useEffect } from "react";
 
// import "./Duct.css";

import Imgone from "./Imgs/1.jpg";
import Imgtwo from "./Imgs/2.jpg";

export default function () {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ ]);
  return (
    <div className="container servicepx">
      <div
        style={{
          //   height: "40vh",
          //   display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <br />
        <br />
        <br />
        <br />

        <div className="heading" align="center">
          <h1 data-aos="fade-down">
            Supply and <br />
            <span>Installation of Grease Traps</span>
          </h1>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col">
            <img src={Imgone} style={{ width: "100%" }} />
          </div>
          <div className="col">
            <p align="center">
              Maintaining a grease trap or interceptor is crucial in order to
              stop fats, oils, and grease (FOG) from combining with water that
              is flushed down the drain. Grease traps and interceptors in food
              service establishments must be cleaned no less frequently than
              once every 90 days. However, to keep your grease trap/interceptor
              from functioning poorly or incorrectly, more frequent cleaning
              intervals might be required. <br />
              <br /> According to municipal regulations, grease traps must be
              properly maintained and cleaned on a regular basis. An unscented
              backyard for a food processing facility necessitates a clean
              grease trap and drainage system. Since its founding, Grease Trap
              cleaning has been the main business activity of Smart Box, an
              approved company. <br />
              <br /> We provide two options for installing grease traps. Grease
              traps made of stainless steel and PVC Every restaurant, cafeteria,
              catering business, and other commercial cooking facility is
              required by the Local Authorities of the United Arab Emirates to
              install a grease trap on their property. We can provide you with
              the specifications and requirements needed to build a grease trap
              at your location based on your preferences.
            </p>
          </div>
        </div>

        <hr />
        
      </div>
    </div>
  );
}
