import React, { useContext, useEffect, useState } from "react";
import "./Styles.css";
import MyContext from "../../../context/MyContext";
import { Card, Input, message, Modal } from "antd";
import Api, { USER_API_URL } from "../../../Api/Api";
import axios from "axios";
import StripeForm from "./Card/Card";

export default function Homecleaning() {
  axios.defaults.withCredentials = true;
  const [cleaner, setCleaner] = useState(0);
  // const [price, set] = useState(0);
  const [count, setCount] = useState(0);
  const [time, setTime] = useState(0);
  // const [time, setTime] = useState(0);
  const [oft, setOft] = useState(0);
  const [date, setDate] = useState(0);

  // --------------------------------------------------------------------
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [visible, setVisible] = useState(false);

  // --------------------------------------------------------------------
  const [selectedDays, setSelectedDays] = useState([]);
  const handleDayClick = (dayName) => {
    if (!selectedDays.includes(dayName)) {
      setSelectedDays([...selectedDays, dayName]); // Add day to selectedDays array
    } else {
      const updatedDays = selectedDays.filter((day) => day !== dayName); // Remove day from selectedDays array
      setSelectedDays(updatedDays);
    }
  };

  const { state, updateState } = useContext(MyContext);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleContactChange = (e) => {
    setContact(e.target.value);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    pushDat();
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  // ------------------------------------------------------------------------
  const HourSpent = [
    {
      name: "Two Hours",
      value: 2,
    },
    {
      name: "Three Hours",
      value: 3,
    },
    {
      name: "Four Hours",
      value: 4,
    },
    {
      name: "Five Hours",
      value: 5,
    },
  ];
  const Days = [
    {
      name: "Monday",
    },
    {
      name: "Tuesday",
    },
    {
      name: "Wednesday",
    },
    {
      name: "Thursday",
    },
    {
      name: "Friday",
    },
    {
      name: "Saturday",
    },
  ];

  const Often = [
    {
      name: "Once",
      description: "Book a one time cleaning session",
    },
    {
      name: "Weekly",
      description:
        "Get the same cleaner each time, Re-schedule easily through the app",
    },
    {
      name: "Multiple times a week",
      description:
        "Get the same cleaner each time, Re-schedule easily through the app",
    },
  ];

  const strg = (datexx) => {
    if (datexx == 0) {
      return "";
    }
    const date = new Date(datexx);
    const formattedDate = date.toLocaleString();
    return formattedDate;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const calculateTotalAmount = () => {
    return 30 * cleaner * count + oft;
  };

  const calculateVAT = () => {
    return (calculateTotalAmount() * 5) / 100;
  };

  const calculateNetCost = () => {
    return calculateTotalAmount() + calculateVAT();
  };
  axios.defaults.withCredentials = true;
  const pushDat = async () => {
    const values = {
      hours: time,
      cleaners: cleaner,
      Material: oft,
      Fregency:
        time === 1 ? "Once" : time === 2 ? "Weekly" : "Multiple times a week",
      days: selectedDays,
      date: new Date(date),
      TotalAmount: calculateTotalAmount(),
      Vat: calculateVAT(),
      Pay: calculateNetCost(),
      Email: email,
      Contact: contact,
      Name: name,
    };

    try {
      const response = await axios.post(`${USER_API_URL}/homeBook`, values);
      console.log("Booking response:", values);
      message.success("Booking successful!");
      // Optionally reset form or update state
    } catch (error) {
      console.error("Booking failed:", error);
      message.error("Booking Failed!");
      // alert("Booking failed. Please try again.");
    }
  };

  return (
    <div className="container">
      <br />
      <Modal
        title="Booking"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Request Booking"}
        width={800}
      >
        <div className="row">
          <div className="col">
            <StripeForm />
          </div>

          <div className="col">
            <div>
              <p>Enter your name:</p>
              <Input
                value={name}
                onChange={handleNameChange}
                style={{ height: 40 }}
                placeholder="Your Name"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your email:</p>
              <Input
                value={email}
                onChange={handleEmailChange}
                style={{ height: 40 }}
                placeholder="Email Address"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your phone:</p>
              <Input
                value={contact}
                onChange={handleContactChange}
                style={{ height: 40 }}
                placeholder="Contact Number"
              />
            </div>
          </div>
        </div>
      </Modal>
      <br />
      <div align="center">
        <div className="heading" align="center">
          <h1 data-aos="fade-down">
            Home Services
            <br />
            {/* <span> Smartboxcleaning Services </span> */}
          </h1>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-lg-7 col-md-6 col-sm-12 colxs-12 ">
          <div className="whit">
            <div>
              <div className="often">
                <h3>How many hours?</h3>
                <div>
                  {HourSpent.map((a, b) => (
                    <label
                      style={
                        a.value == count
                          ? { background: "#df2528", color: "white" }
                          : { background: "none" }
                      }
                      onClick={() => setCount(a.value)}
                    >
                      {a.name}
                    </label>
                  ))}
                </div>
              </div>
              <br />

              <div className="increment">
                <h3>Number Of Cleaners</h3>
                <div className="tabc">
                  <label onClick={() => setCleaner(cleaner - 1)}>-</label>
                  <span>{cleaner}</span>
                  <label onClick={() => setCleaner(cleaner + 1)}>+</label>
                </div>
              </div>

              <br />
              <div className="datesi">
                <h3>How often do you need cleaning ?</h3>
                <div>
                  {Often.map((a, b) => (
                    <div
                      onClick={() => setTime(b + 1)}
                      style={
                        time == b + 1
                          ? {
                              border: "1px solid grey",
                              marginBottom: "10px",
                              padding: "5px",
                              borderRadius: 10,
                              paddingLeft: "20px",
                              background: "#df2528",
                              color: "white",
                            }
                          : {
                              border: "1px solid grey",
                              marginBottom: "10px",
                              padding: "5px",
                              borderRadius: 10,
                              paddingLeft: "20px",
                              background: "none",
                            }
                      }
                    >
                      <label style={{ fontWeight: "bold" }}>{a.name}</label>
                      <p>{a.description}</p>
                    </div>
                  ))}
                </div>
              </div>
              <br />
              <div
                style={time == 3 ? { display: "block" } : { display: "none" }}
              >
                <h3>Which days of the week do you want the service ?</h3>
                <div className="often">
                  {Days.map((day, index) => (
                    <label
                      key={index}
                      style={{
                        background: selectedDays.includes(day.name)
                          ? "#df2528"
                          : "none",
                      }}
                      onClick={() => handleDayClick(day.name)}
                    >
                      {day.name}
                    </label>
                  ))}
                </div>
              </div>
              <br />
              <div>
                <h3>Extra Cleaning Materials</h3>
                <div className="dappbox">
                  <div className="often" style={{ marginRight: 20 }}>
                    <label
                      onClick={() => setOft(10)}
                      style={
                        oft == 10
                          ? { background: "#df2528", color: "white" }
                          : { background: "none" }
                      }
                    >
                      Yes
                    </label>
                    <label
                      onClick={() => setOft(0)}
                      style={
                        oft == 0
                          ? { background: "#df2528" }
                          : { background: "none" }
                      }
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <br />
              <div className="datesi">
                <h3>Pick your start date and time ?</h3>
                <div>
                  <input
                    style={{
                      borderRadius: 10,
                    }}
                    type="datetime-local"
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col" style={{ padding: 10 }}>
          <div className="whit" style={{ background: "white", margin: "5px" }}>
            <br />
            <h2>Booking Summary</h2>
            <hr />
            <div>
              <div className="row">
                <div className="col" style={{ fontWeight: "bold" }}>
                  No. of hours
                </div>
                <div className="col">{count}</div>
              </div>
              <div className="row">
                <div className="col" style={{ fontWeight: "bold" }}>
                  No Of Cleaners
                </div>
                <div className="col">{cleaner}</div>
              </div>
              <div className="row">
                <div className="col" style={{ fontWeight: "bold" }}>
                  Materials
                </div>
                <div className="col">
                  {oft == 1 ? `${oft} AED` : `${oft} AED`}
                </div>
              </div>
              <div className="row">
                <div className="col" style={{ fontWeight: "bold" }}>
                  Frequency
                </div>
                <div className="col">
                  {time == 1
                    ? "Once "
                    : time == 2
                    ? "Weekly "
                    : "Multiple times a week"}
                </div>
              </div>
              <div className="row">
                <div className="col" style={{ fontWeight: "bold" }}>
                  Days
                </div>
                <div className="col">
                  {selectedDays.map((a, b) => (
                    <label style={{ marginRight: 10 }}>{a} </label>
                  ))}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">Date and Time : </div>{" "}
              <div className="col">{strg(date)}</div>
            </div>

            <hr />
            <div className="row">
              <div className="col">Total Amount :</div>
              <div className="col">{calculateTotalAmount()} AED</div>
            </div>
            <div className="row">
              <div className="col">VAT (5%) :</div>
              <div className="col">{calculateVAT()} AED</div>
            </div>
            <div className="row">
              <div className="col" style={{ fontWeight: "bold" }}>
                Net to pay :
              </div>
              <div className="col" style={{ fontSize: "23px" }}>
                {calculateNetCost()} AED
              </div>
            </div>
          </div>
          <br />
          <button
            className="btn btn-success bknow"
            style={{ padding: "10px" }}
            onClick={() => showModal()}
          >
            BOOK NOW
          </button>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
