import React, { useState, useEffect, useRef } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import './Blog.css';

import BlogPost from './BlogPost';
import bannerabout from "./1.jpg";


export default function Blog() {
  const [posts, setPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState(3);
  const [loading, setLoading] = useState(false);
  const loaderRef = useRef(null);
  const { path, url } = useRouteMatch();

  useEffect(() => {
    fetch('/blogPosts.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setPosts(data);
      })
      .catch(error => console.error('Error fetching the blog posts:', error));
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loading) {
        loadMorePosts();
      }
    });
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loading]);

  const loadMorePosts = () => {
    setLoading(true);
    setTimeout(() => {
      setVisiblePosts(prevVisiblePosts => prevVisiblePosts + 3);
      setLoading(false);
    }, 1000);
  };

  return (

    <div id="blog">
      {/* Breadcrumb Section */}
      <section className="breadcrumb-style1">
        <div className="breadcrumb-style1-bg" style={{
    backgroundImage: `url(${bannerabout})`,
    height: '200px'
  }}>
        </div>
        <div className="container">
          <div className="inner-content">
            <div className="title">
              <h2>Blog Section</h2>
            </div>
            <div className="breadcrumb-menu">
              <ul className="clearfix">
                <li><Link to="/">Home</Link></li>
                <li className="active">Our Blog</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Blog Posts Section */}
      <section className="blog-page-one">
        <div className="container">
          <div className="row">
            {posts.slice(0, visiblePosts).map((post, index) => (
              <div key={index} className="col-xl-4 col-md-6">
                <div className="single-blog-style1">
                  <div className="single-blog-style1__top">
                    <div className="meta-box">
                      <ul className="meta-info">
                        <li><a href="#">{post.categories}</a></li>
                        <li><a href="#">{new Date(post.date).toLocaleDateString()}</a></li>
                      </ul>
                    </div>
                    <h2>
                      <Link to={`${url}/${post.urls}`}>{post.title}</Link>
                    </h2>
                  </div>
                  <div className="img-holder">
                    <div className="inner">
                      <img src={post.image} alt={post.title} />
                    </div>
                  </div>
                  <div className="single-blog-style1__bottom">
                    <p>{post.description}</p>
                   
                    
                      <Link to={`${url}/${post.urls}`} className="back-to-blog">Read More</Link>
                    
                  </div>
                </div>
              </div>
            ))}
          </div>
          {loading && <p>Loading...</p>}
          <div ref={loaderRef}></div>
        </div>
      </section>
      
      <Switch>
        <Route exact path={path}>
          {/* Blog main view */}
        </Route>
        <Route path={`${path}/:urls`}>
          <BlogPost posts={posts} />
        </Route>
      </Switch>
    </div>
  );
}
