import React, { useState } from "react";
// import Card from "../../Card/Card";
import { Input, Modal } from "antd";
import axios from "axios";
import StripeForm from "./Card/Card";

export default function CarpetCleaning() {
  axios.defaults.withCredentials = true;
  const [carpetSizes, setCarpetSizes] = useState([
    { size: "Small Size ", selected: false, price: 100 },
    { size: "Medium Size (Up to 8 M)", selected: false, price: 120 },
    { size: "Large Carpet (Up to 12 M)", selected: false, price: 200 },
    { size: "X-large (Up to 12 M)", selected: false, price: 250 },
  ]);

  const [carpetQuantities, setCarpetQuantities] = useState([
    { quantity: "1 Piece", selected: false, count: 1 },
    { quantity: "2 Pieces", selected: false, count: 2 },
    { quantity: "3 Pieces", selected: false, count: 3 },
    { quantity: "4 Pieces", selected: false, count: 4 },
  ]);

  const [startDate, setStartDate] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [visible, setVisible] = useState(false);

  axios.defaults.withCredentials = true;

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleContactChange = (e) => {
    setContact(e.target.value);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    // if (isEdit) {
    //   onUpdateBooking();
    // } else {
    handleBooking();
    // }AcRoutes
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleBooking = async () => {
    const selectedSize = carpetSizes.find((carpet) => carpet.selected);
    const selectedQuantity = carpetQuantities.find(
      (quantity) => quantity.selected
    );
    if (!selectedSize || !selectedQuantity || !startDate) {
      alert("Please select carpet size, quantity, and start date.");
      return;
    }
    const bookingData = {
      size: selectedSize.size,
      price: selectedSize.price,
      quantity: selectedQuantity.quantity,
      count: selectedQuantity.count,
      startDate: new Date(startDate),
      totalAmount: calculateTotalAmount(),
      vat: calculateVAT(),
      netCost: calculateNetCost(),
      Email: email,
      Contact: contact,
      Name: name,
    };
    try {
      console.log("bookingData", bookingData)
      const response = await axios.post(`${USER_API_URL}/carpetbooking`, bookingData);
      message.success("Booking successful!");
    } catch (error) {
      // message.success("Booking failed. Please try again.");
    }
  };

  const handleSizeClick = (index) => {
    const updatedSizes = carpetSizes.map((carpet, i) => ({
      ...carpet,
      selected: i === index ? true : false,
    }));
    setCarpetSizes(updatedSizes);
  };

  const handleQuantityClick = (index) => {
    const updatedQuantities = carpetQuantities.map((quantity, i) => ({
      ...quantity,
      selected: i === index ? true : false,
    }));
    setCarpetQuantities(updatedQuantities);
  };

  const calculateTotalAmount = () => {
    let total = 0;
    carpetSizes.forEach((carpetSize) => {
      carpetQuantities.forEach((quantity) => {
        if (carpetSize.selected && quantity.selected) {
          total += carpetSize.price * quantity.count;
        }
      });
    });
    return total;
  };

  const calculateVAT = () => {
    const totalAmount = calculateTotalAmount();
    return (totalAmount * 0.05).toFixed(2);
  };

  const calculateNetCost = () => {
    const totalAmount = calculateTotalAmount();
    const vat = calculateVAT();
    return (totalAmount + parseFloat(vat)).toFixed(2);
  };

  return (
    <div className="container">
      <br />
      <Modal
        title="Booking"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Request Booking"}
        width={800}
      >
        <div className="row">
          <div className="col">
            {/* <Card/> */}
            <StripeForm />
          </div>
          <div className="col">
            <div>
              <p>Enter your name:</p>
              <Input
                value={name}
                onChange={handleNameChange}
                style={{ height: 40 }}
                placeholder="Your Name"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your email:</p>
              <Input
                value={email}
                onChange={handleEmailChange}
                style={{ height: 40 }}
                placeholder="Email Address"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your phone:</p>
              <Input
                value={contact}
                onChange={handleContactChange}
                style={{ height: 40 }}
                placeholder="Contact Number"
              />
            </div>
          </div>
        </div>
      </Modal>
      <br />
      <div align="center">
        <div className="heading" align="center">
          <h1 data-aos="fade-down">
            Carpet Cleaning
            <br />
            {/* <span> Smartboxcleaning Services </span> */}
          </h1>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-lg-7 col-md-6 col-sm-12 colxs-12 ">
          <div className="whit">
            <div>
              <div className="often">
                <h3>What size carpet do you want to clean?</h3>
                <div>
                  {carpetSizes.map((carpet, index) => (
                    <label
                      key={index}
                      onClick={() => handleSizeClick(index)}
                      style={
                        carpet.selected
                          ? { background: "lightgrey", width: "32%" }
                          : { background: "none", width: "32%" }
                      }
                    >
                      {carpet.size}
                    </label>
                  ))}
                </div>
              </div>
              <br />
              <div className="often">
                <h3>How many carpets do you want to clean?</h3>
                <div>
                  {carpetQuantities.map((quantity, index) => (
                    <label
                      key={index}
                      onClick={() => handleQuantityClick(index)}
                      style={
                        quantity.selected
                          ? { background: "lightgrey" }
                          : { background: "none" }
                      }
                    >
                      {quantity.quantity}
                    </label>
                  ))}
                </div>
              </div>
              <div className="datesi">
                <h3>Pick your start date and time?</h3>
                <div>
                  <input
                    type="datetime-local"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col" style={{ padding: 10 }}>
          <div className="whit" style={{ background: "white", margin: "5px" }}>
            <br />
            <h2>Booking Summary</h2>
            <hr />

            {carpetSizes.map((carpet, index) => {
              if (carpet.selected) {
                return (
                  <div className="row" key={index}>
                    <div className="col">{carpet.size}</div>
                    <div className="col">{carpet.price}</div>
                  </div>
                );
              }
              return null;
            })}

            {/* Display summary based on selected sizes and quantities */}
            <br />
            <div className="row">
              <div className="col">Total Amount : </div>{" "}
              <div className="col">{calculateTotalAmount()} AED</div>
            </div>
            <div className="row">
              <div className="col">VAT (5%) : </div>{" "}
              <div className="col">{calculateVAT()} AED</div>
            </div>
            <div className="row">
              <div className="col">Net to pay : </div>{" "}
              <div className="col">{calculateNetCost()} AED</div>
            </div>
          </div>
          <button className="btn btn-success bknow" onClick={showModal}>
            BOOK NOW
          </button>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
