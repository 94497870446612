import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import Logo from '../../img/Icon.png';

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    if (servicesDropdownOpen) {
      setServicesDropdownOpen(false);
    }
  };

  const toggleServicesDropdown = (e) => {
    e.preventDefault();
    setServicesDropdownOpen(!servicesDropdownOpen);
  };

  const handleNavItemClick = () => {
    setMobileMenuOpen(false);
    setServicesDropdownOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 100);
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setServicesDropdownOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const services = [
    { name: "Kitchen Hood, Duct & Exhaust Fan Cleaning", route: "KitchenHoodDuctExhaustFanCleaning" },
    { name: "AC Duct Cleaning and Disinfection", route: "AcDuctCleaningandDisinfection" },
    { name: "Grease Trap Cleaning", route: "Greasetrapcleaning" },
    { name: "Water Tank Cleaning", route: "WaterTankCleaning" },
    { name: "Civil and MEP Works", route: "CivilandMepWorks" },
    { name: "Drain Line Cleaning", route: "DrainLineCleaning" },
    { name: "Grease Trap and Sumpit Cleaning", route: "GreaseTrapAndSumpitCleaning" }
  ];

  return (
    <header className={`main-header ${isSticky ? 'stricky-fixed' : ''}`}>
      <div className={`header-upper ${isSticky ? 'hidden' : ''}`}>
        <div className="main-header__top">
          <div className="container">
            <div className="main-header__top-inner">
              <div className="main-header__top-left">
                <p>Welcome To <Link to="/" onClick={handleNavItemClick}>Smart Box</Link> Cleaning Services!</p>
              </div>
              <div className="main-header__top-right">
                <div className="header-social-link-style1">
                  <ul>
                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-header__bottom">
          <div className="container">
            <div className="main-header__bottom-inner">
              <div className="main-header__bottom-left">
                <div className="logo-box-style1">
                  <Link to="/" onClick={handleNavItemClick}>
                    <img src={Logo} alt="Smart Box Cleaning Services" />
                  </Link>
                </div>
              </div>
              <div className="main-header__bottom-right">
                <div className="contact-info-box-style1">
                  <ul>
                    <li>
                      <div className="icon">
                        <span className="icon-phone1"><i className="fas fa-phone-alt"></i></span>
                      </div>
                      <div className="text">
                        <p>Call us</p>
                        <h4><a href="tel:97122463894">+971569147880</a></h4>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-clock"><i className="far fa-clock"></i></span>
                      </div>
                      <div className="text">
                        <p>MON  - SAT</p>
                        <h4>8am - 7pm</h4>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-home"><i className="fas fa-map-marker-alt"></i></span>
                      </div>
                      <div className="text">
                        <p> Next to ADCB Head Office - Al Danah</p>
                        <h4>Abu Dhabi - United Arab</h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="main-menu main-menu-style1">
        <div className="main-menu__wrapper clearfix">
          <div className="container">
            <div className="main-menu__wrapper-inner">
              <div className="main-menu-style1__left">
                <div className="logo-box-style1 mobile-logo">
                  <Link to="/" onClick={handleNavItemClick}>
                    <img src={Logo} alt="Smart Box Cleaning Services" />
                  </Link>
                </div>
                <div className="main-menu-box">
                  <a href="#" className="mobile-nav__toggler" onClick={toggleMobileMenu}>
                    <i className="fa fa-bars"></i>
                  </a>
                  <ul className={`main-menu__list ${mobileMenuOpen ? 'mobile-menu-visible' : ''}`}>
                    <li><Link to="/" onClick={handleNavItemClick}>Home</Link></li>
                    <li><Link to="/about" onClick={handleNavItemClick}>About Us</Link></li>
                    <li className="dropdown" ref={dropdownRef}>
                      <a href="#" onClick={toggleServicesDropdown}>
                        Our Services
                        <i className={`fa fa-angle-down ${servicesDropdownOpen ? 'rotate' : ''}`}></i>
                      </a>
                      <ul className={`dropdown-menu ${servicesDropdownOpen ? 'show' : ''}`}>
                        <li><Link to="/services" onClick={handleNavItemClick}>View All Services</Link></li>
                        {services.map((service, index) => (
                          <li key={index}>
                            <Link to={`/${service.route}`} onClick={handleNavItemClick}>
                              {service.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li><Link to="/blog" onClick={handleNavItemClick}>Blog</Link></li>
                    <li><Link to="/ContactUs" onClick={handleNavItemClick}>Contact Us</Link></li>
                  </ul>
                </div>
              </div>
              <div className="main-menu-style1__right">
                <div className="header-btn-style1">
                <Link 
  to="/quote" 
  className="btn-one" 
  onClick={handleNavItemClick}
  style={{ color: 'white' }}  // Added this line
>
  <span className="txt">Get a quote</span>
</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;